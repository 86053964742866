<template>
  <div class="page-404">
    <h1>404 未找到对应页面，请联系管理员</h1>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
<style>
</style>
